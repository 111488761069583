import React from 'react'
import Layout from '../components/layout'
import Block from '../components/block'

const ContactPageSuccess = () => {
    return (
        <Layout noNav={true}>
            <Block title="Thanks!">
                <p>I will be getting back to you shortly.</p>
            </Block>
        </Layout>
    )   
}

export default ContactPageSuccess